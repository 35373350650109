module.exports ={
  "propertyService": "https://w7g826x3pe.execute-api.us-east-1.amazonaws.com/alex",
  "domusoApiService": "https://rp7w1e8z46.execute-api.us-east-1.amazonaws.com/alex",
  "appService": "https://domusograilsweb-alex.devmuso.com",
  "reservationService": "https://txyhrljnoc.execute-api.us-east-1.amazonaws.com/alex",
  "userPoolId": "us-east-1_tuVPUHTxf",
  "authService": "https://vdy5gcnqh3.execute-api.us-east-1.amazonaws.com/alex",
  "userPoolWebClientId": "7j131uo6qmknsnblj9pr1o04it",
  "emailLinkBase": "https://domusograilsweb-alex.devmuso.com",
  "payWithUrl": "https://paywith.devmuso.com",
  "supportEmail": "support-test@devmuso.com",
  "residentWebUI": "https://resident-alex.devmuso.com",
  "maintenanceUrl": "https://vdy5gcnqh3.execute-api.us-east-1.amazonaws.com/alex/maintenance",
  "env": "alex"
}